// Created by kdw0601 on 2023-02-07
import { useRecoilCallback, useRecoilValue } from 'recoil'
import Portal from '../portal/Portal'
import Toast from '../toast/Toast'
import IToastMessageProps from './IToastMessageProps'
import { messageListAtom } from './useToastMessage'

const GlobalToastMessage = () => {
  const messageList = useRecoilValue(messageListAtom)
  const removeMessage = useRecoilCallback(
    ({ set }) =>
      (message: IToastMessageProps) => {
        set(messageListAtom, (prev) =>
          prev.filter((v) => v.key !== message.key)
        )
      }
  )

  return (
    <Portal domId={'message-root'}>
      {messageList.map((v) => (
        <Toast
          {...v}
          key={v.key}
          ref={(el) => (v.ref = el)}
          onUnmount={() => removeMessage(v)}
        />
      ))}
    </Portal>
  )
}

export default GlobalToastMessage
