// Created by kdw0601 on 2023-02-01
import classNames from 'classnames'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import IToastMessageProps, { ToastEmojis } from './IToastMessageProps'
import styles from './Toast.module.scss'
import classNamesBind from 'classnames/bind'

const cx = classNamesBind.bind(styles)

export interface ToastRefType {
  closeToast: () => void
}

interface Props extends IToastMessageProps {
  onUnmount?: () => void
  className?: string
}

const Toast = forwardRef<ToastRefType, Props>(function Toast(
  {
    emoji,
    className,
    text,
    durationMillisec = 0,
    onUnmount,
    closable,
    buttons,
    width,
    type,
    bottomOffset = 24,
  },
  ref
) {
  const [isVisible, setIsVisible] = useState(false)
  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null)
  useImperativeHandle(ref, () => ({
    closeToast,
  }))

  useEffect(() => {
    setTimeoutId(
      setTimeout(() => {
        setIsVisible(true)

        if (durationMillisec > 0) {
          setTimeout(() => {
            setIsVisible(false)
          }, durationMillisec)
        }
      }, 200)
    )
  }, [])

  const handleTransitionEnd = (e: React.TransitionEvent) => {
    if (!isVisible && e.propertyName === 'opacity') {
      onUnmount?.()
    }
  }

  const closeToast = () => {
    timeoutId && clearTimeout(timeoutId)
    setIsVisible(false)
  }

  return (
    <div
      className={cx('container', className, type, {
        visible: isVisible,
      })}
      onTransitionEnd={handleTransitionEnd}
      style={{
        bottom: isVisible ? bottomOffset : bottomOffset - 24,
        minWidth: width,
      }}
      data-designsystem
    >
      <div className={cx('left')}>
        {emoji && <span>{ToastEmojis[emoji]}</span>}
        <span className={cx('text')}>{text}</span>
      </div>
      {(buttons || closable) && (
        <div className={cx('button-wrap')}>
          {buttons?.map((v, i) => (
            <button
              onClick={(e) => {
                e.currentTarget.blur()
                v.onClick ? v.onClick(closeToast) : closeToast()
              }}
              key={i}
            >
              {v.text}
            </button>
          ))}
          {closable && (
            <button
              className={classNames(
                'bg-slate-100',
                'text-slate-800',
                'rounded-md',
                'h-8',
                'flex',
                'items-center',
                'justify-center',
                'px-3',
                'outline-none',
                'w-[64px]',
                'hover:bg-slate-200',
                'disabled:opacity-50'
              )}
              color={'teal'}
              onClick={closeToast}
            >
              확인
            </button>
          )}
        </div>
      )}
    </div>
  )
})

export default Toast
