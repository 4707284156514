import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import './index.css'
import { RecoilRoot } from 'recoil'

import App from './App'
import GlobalToastMessage from './components/common/toast/GlobalToastMessage'
import reportWebVitals from './reportWebVitals'

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
})

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        <GlobalToastMessage />
      </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
