import React from 'react'
import { Route, Routes, Outlet } from 'react-router-dom'

import LoginPage from './components/login/LoginPage'
import UserListPage from './components/user/UserListPage'
import Home from './components/home/Home'
import UserDetailPage from "./components/user/UserDetailPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="/users" element={<Outlet />}>
          <Route index element={<UserListPage/>}/>
          <Route path={':sn'} element={<UserDetailPage/>}/>
      </Route>
    </Routes>
  )
}

export default App
